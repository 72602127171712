import React from 'react'
import { Link } from 'react-router-dom'
function Header() {
  return (
    <header className="header">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="logo">
                  <div className="logo-text">
                    <Link to="/" class="logo">PortugalInfos</Link>
                  </div>
                  
              </div>
            </div>
            <div className="col-sm-4"></div>
            <div className="col-sm-4">
                <ul className="nav">
                  <li className="nav-item active">
                    <Link to="/" className="nav-link">Home</Link>
                  </li>
                  <li className="nav-item active">
                    <Link to="/contact" className="nav-link">Contact Us</Link>
                  </li>
                </ul>
            </div>
          </div>
        </div>
    </header>
  )
}

export default Header