import React from 'react'
import axios from 'axios';
import {Link} from 'react-router-dom'
const baseURL = "https://legal.ptinfos.com/api/web_data";

function Body() {
    const [post, setPost] = React.useState(null);
    React.useEffect(() => {
      axios.get(baseURL).then((response) => {
        setPost(response.data);
      });
    }, []);
  
    if (!post) return null;
  return (
    <section className="body">
       <div className="container banner">
            <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-9 text-center">
                    <h1 className="banner-text">
                        Legal Support Lisbon
                    </h1>
                    <h2>We are team of experienced legal service provider</h2>
                </div>
            </div>
            <div className="row icon-boxes">
                <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0 aos-init aos-animate">
                    <div className='icon-box'>
                        <h3><Link to="/immigration" className='page-link'>Immigration Policy</Link></h3>
                        <div className='content'>{post.immigration.substring(0, 250)}</div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0 aos-init aos-animate">
                    <div className='icon-box'>
                        <h3><Link to="/change" className='page-link'>New Change</Link></h3>
                        <div className='content'>{post.change.substring(0, 250)}</div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0 aos-init aos-animate">
                    <div className='icon-box'>
                        <h3><Link to="/marriage" className='page-link'>Marriage Law</Link></h3>
                        <div className='content'>{post.marriage.substring(0, 250)}</div>
                    </div>
                </div>
            </div>
            <div className="row icon-boxes mt-3">
                <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0 aos-init aos-animate">
                    <div className='icon-box'>
                        <h3><Link to="/make" className='page-link'>Making TR/PR</Link></h3>
                        <div className='content'>{post.make.substring(0, 250)}</div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0 aos-init aos-animate">
                    <div className='icon-box'>
                        <h3><Link to="/renew" className='page-link'>Renewing TR/PR</Link></h3>
                        <div className='content'>{post.renew.substring(0, 250)}</div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0 aos-init aos-animate">
                    <div className='icon-box'>
                        <h3><Link to="/family" className='page-link'>Family Regrouping</Link></h3>
                        <div className='content'>{post.family.substring(0, 250)}</div>
                    </div>
                </div>
            </div>
            <div className="row icon-boxes mt-3">
                <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0 aos-init aos-animate">
                    <div className='icon-box'>
                        <h3><Link to="/apply" className="page-link">Nationality Apply</Link></h3>
                        <div className='content'>{post.apply.substring(0, 250)}</div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0 aos-init aos-animate">
                    <div className='icon-box'>
                        <h3><Link to="/court" className="page-link" >Court Process</Link></h3>
                        <div className='content'>{post.court.substring(0, 250)}</div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0 aos-init aos-animate">
                    <div className='icon-box'>
                        <h3><Link to="/labor" className="page-link" >Labor ACT</Link></h3>
                        <div className='content'>{post.labor.substring(0, 250)}</div>
                    </div>
                </div>
            </div>
        </div> 
    </section>
  )
}

export default Body