import React from 'react'
import Header from '../Header'
import axios from 'axios';
export default function Change() {
  const baseURL = "https://legal.ptinfos.com/api/web_data";
  const [post, setPost] = React.useState(null);
  React.useEffect(() => {
    axios.get(baseURL).then((response) => {
      setPost(response.data);
    });
  }, []);

  if (!post) return null;
  return (
    <>
      <Header/>
      <div className='container'>
        <div className="row my-3">
          <div className="col-sm-12">
            <h1 className='text-center'>Change TR/PR</h1>
            <div className='text-center mt-2'><img src={post.change_img} alt="Change TR/PR" className = "img-fluid text-center" /></div>
            <div className='content mt-3'>
              {post.change}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}