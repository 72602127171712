import React from 'react';
import './App.css';
// import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Body from './components/Body';
import Footer from './components/Footer';
import Court from './components/pages/Court';
import Immigration from './components/pages/Immigration';
import Change from './components/pages/Change';
import Make from './components/pages/Make';
import Renew from './components/pages/Renew';
import Family from './components/pages/Family'
import Apply from './components/pages/Apply'
import Labor from './components/pages/Labor'
import Contact from './components/pages/Contact'
import Marriage from './components/pages/Marriage'
function App() {
  return (
    <>
    <div className="App">
      <Router>
        {/* <Body/> */}
        <Routes>
            <Route path='/' element={<Body/>} />
            <Route path="/court" element={<Court />}/>
            <Route path="/immigration" element={<Immigration />} />
            <Route path='/change' element={<Change />}/>
            <Route path='/making' element={<Make />}/>
            <Route path='/renew' element={<Renew />}/>
            <Route path='/family' element={<Family />}/>
            <Route path='/apply' element={<Apply />}/>
            <Route path='/labor' element={<Labor />}/>
            <Route path='/contact' element={<Contact />}/>
            <Route path="/marriage" element={<Marriage />}/>
        </Routes>
      </Router>
    </div>
    <Footer/>
    </>
  );
}

export default App;
