import React from 'react'

export default function Footer() {
    const current = new Date()
    const year = current.getFullYear()
  return (
    <div className="container">
        <div className="row">
            <div className="col-sm-12">
                <div className="copyright my-4">
                Copyright &copy; {year} Legal Support Lisbon. All right reserved.
                </div>
            </div>
        </div>
    </div>
  )
}
